import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import VueShortkey from "vue-shortkey";

import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./assets/scss/style.scss";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueShortkey, { prevent: ["input", "textarea"] });

new Vue({
	store,
	router,
	render: (h) => h(App)
}).$mount("#app");
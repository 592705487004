<template>
	<div>
		<h1 :class="$style.name">FilmInfo</h1>
		<div class="mt-n2 text-muted text-uppercase">Moving Picture Information Aggregation</div>
	</div>
</template>

<script>
	export default {
	};
</script>

<style lang="scss" module>
	.name {
		font-size: 4rem;
	}
</style>
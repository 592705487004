<template>
	<div :class="$style.container" class="d-flex justify-content-center align-items-center">
		<div>
			<Logo/>
			<b-spinner :class="$style.spinner" type="grow" variant="secondary" label="Loading..."></b-spinner>
		</div>
	</div>
</template>

<script>
	import Logo from "../components/Logo.vue";

	export default {
		name: "home",
		computed: {
			loggedIn: function() {
				return this.$store.getters.loggedIn;
			}
		},
		mounted() {
			this.$store.commit("SET_SEARCH_RESULTS", null);

			let redirectDestination = this.loggedIn? "film": "login";

			this.$router.push({ name: redirectDestination });
		},
		components: {
			Logo
		}
	};
</script>

<style lang="scss" module>
	.container {
		height: 100vh;
	}

	$size: 3rem;
	.spinner {
		width: $size;
		height: $size;
		margin-top: 40%;
	}
</style>
<template>
	<div v-if="progress < 100" :class="$style.progress" :style="style" class="bg-info">
		<div :class="$style.shadow"></div>
	</div>
</template>

<script>
	const TRICKLING_INTERVAL = 750;

	export default {
		data() {
			return {
				intervalId: 0
			};
		},
		computed: {
			progress: function() {
				return this.$store.state.progress;
			},
			style: function() {
				return {
					width: this.progress + "%"
				};
			}
		},
		watch: {
			progress: function(newValue) {
				if (newValue >= 100) {
					clearInterval(this.intervalId);
					this.intervalId = 0;
					return;
				}

				// Already trickling
				if (this.intervalId !== 0) return;

				this.intervalId = setInterval(() => this.$store.commit("SET_PROGRESS", this.progress + Math.random() * 0.5), TRICKLING_INTERVAL);
			}
		},
		methods: {

		}
	};
</script>

<style lang="scss" module>
	.progress {
		position: fixed;
		top: 0;
		height: 2px;
		transition: all 300ms ease 0s;
		z-index: 1100;
	}

	.shadow {
		display: block;
		position: absolute;
		right: 0px;
		width: 100px;
		height: 100%;
		box-shadow: 0 0 10px #17a2b8, 0 0 5px #17a2b8;
		transform: rotate(3deg) translate(0px, -4px);
}
</style>
<template>
	<b-navbar v-if="loggedIn" fixed="top" variant="white" :class="$style.navbar">
		<b-navbar-nav class="w-100">
			<input ref="input" type="text" v-model.trim="query" :class="$style.input" class="form-control" placeholder="Search"
				   @keypress="search"
				   @click="selectText"
				   @contextmenu="selectText"
				   @blur="hasFocus = false">
			<b-icon-x v-if="query !== ''" :class="$style.clear" font-scale="1.75" aria-hidden="true" @click="clear"/>
		</b-navbar-nav>

		<b-navbar-nav v-if="loggedIn" class="ml-auto">
			<b-nav-item-dropdown right no-caret menu-class="py-1">
				<template v-slot:button-content>
					<b-icon-person-square :class="$style.user" font-scale="2.4" class="ml-1"/>
				</template>

				<b-dropdown-item to="/">Home</b-dropdown-item>
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item to="/watchlist">Watchlist</b-dropdown-item>
				<b-dropdown-item to="/ratings">Ratings</b-dropdown-item>
				<b-dropdown-item to="/account">My account</b-dropdown-item>
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item to="/logout">Sign Out</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
	import { BIconX, BIconPersonSquare } from "bootstrap-vue";

	export default {
		data() {
			return {
				query: "",
				lastQuery: "",
				hasFocus: false
			};
		},
		computed: {
			loggedIn: function() {
				return this.$store.getters.loggedIn;
			},
			searchResults: function() {
				return this.$store.state.searchResults;
			}
		},
		methods: {
			search: function($event) {
				if ($event.key !== "Enter" || !this.query || this.query == this.lastQuery) return;

				this.lastQuery = this.query;

				const regExEan = /\d{12,14}|\d{8}/;
				const action = regExEan.test(this.query)? "SEARCH_EAN": "SEARCH_NAME";

				this.$store.dispatch(action, this.query);
				this.$router.push({ name: "film" }).catch(() => { /* ignore */ });
			},
			clear: function() {
				this.query = "";
				this.focus();
			},
			selectText: function() {
				if (this.hasFocus) return;

				this.hasFocus = true;
				this.$refs.input.select();
			},
			focus: function() {
				if (this.hasFocus) return;

				this.$refs.input.focus();
				this.hasFocus = true;
			}
		},
		watch: {
			searchResults: function(newValue) {
				if (newValue !== null) return;

				this.query = "";
				this.lastQuery = "";
			}
		},
		components: {
			BIconX,
			BIconPersonSquare
		}
	};
</script>

<style lang="scss" module>
	.navbar {
		padding-right: 0.55rem;
	}

	.input {
		&::placeholder {
			color: #ccc!important;
		}
	}

	.clear {
		position: absolute;
		width: auto;
		top: 21px;
		right: 75px;
		margin-top: 0;
		z-index: 10;
		cursor: pointer;
		pointer-events: auto;
		color: #ddd;
	}

	.user {
		color: #ced4da;
	}
</style>
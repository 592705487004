<template>
	<div :class="$style.app">
		<LoadingProgressBar/>
		<ErrorMessage/>
		<SearchBar/>
		<router-view/>
		<Footer/>
	</div>
</template>

<script>
import LoadingProgressBar from "./components/LoadingProgressBar.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import SearchBar from "./components/SearchBar.vue";
import Footer from "./components/Footer.vue";

export default {
	name: "App",
	components: {
		LoadingProgressBar,
		ErrorMessage,
		SearchBar,
		Footer
	}
};
</script>

<style lang="scss">
	html, body {
		height: calc(100% - 32px);
	}

	a:focus {
		outline: none;
	}

	a.disabled {
		pointer-events: none;
	}

	.link {
		cursor: pointer;
	}

	.link-uncolored {
		color: inherit;

		&:hover {
			color: inherit;
		}
	}

	.link-button {
		color: inherit;
		text-decoration: none;
		user-select: none;

		&:hover {
			text-decoration: none;
		}
	}

	.btn-expand {
		margin-top: -0.5rem;
		margin-bottom: -1.5rem;
	}

	.card {
		margin-bottom: 2rem;
	}

	hr.dropdown-divider {
		margin: 0.2rem 0;
	}

	.mw-unset {
		min-width: unset!important;
	}

	.wh-100 {
		width: 100%;
		height: 100%;
	}

	.no-select {
		user-select: none;
	}

	.collapsed > .when-opened,
	:not(.collapsed) > .when-closed {
		display: none;
	}

	.spoiler {
		transition: filter 250ms;
		transition: text-shadow 500ms;

		&:not(:hover) {
			text-shadow: 0 0 1.0ex black;
			color: transparent!important;
			user-select: none;
			filter: blur(5px);
			transition: filter 750ms;
		}
	}

	div.list-group-item-compact {
		padding: 0.5rem 1rem;
	}

	.animated {
		animation-duration: 0.9s;
		animation-fill-mode: both;
	}
</style>

<style lang="scss" module>
	.app {
		margin-top: 4rem;
		text-align: center;
		color: #2c3e50;

		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: Home
	},

	{
		path: "/login",
		name: "login",

		meta: { title: "Login" },

		// Route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* WebpackChunkName: "user" */ "../views/Login.vue")
	},

	{
		path: "/logout",
		name: "logout",

		meta: { title: "Logout" },

		component: () => import(/* WebpackChunkName: "user" */ "../views/Logout.vue")
	},

	{
		path: "/account",
		name: "account",

		meta: { title: "Account" },

		component: () => import(/* WebpackChunkName: "user" */ "../views/Account.vue")
	},

	{
		path: "/movie/:id?",
		name: "movie",
		redirect: { name: "film" }
	},

	{
		path: "/film/:id?",
		name: "film",
		props: { type: "film" },
		component: () => import(/* WebpackChunkName: "filminfo" */ "../views/Film.vue")
	},

	{
		path: "/tv/:id?",
		name: "tv",
		props: { type: "tv" },
		component: () => import(/* WebpackChunkName: "filminfo" */ "../views/Film.vue")
	},

	{
		path: "/watchlist",
		name: "watchlist",
		meta: { title: "Watchlist" },

		component: () => import(/* WebpackChunkName: "user" */ "../views/Watchlist.vue")
	},
	{
		path: "/ratings",
		name: "ratings",
		meta: { title: "Ratings" },

		component: () => import(/* WebpackChunkName: "user" */ "../views/Ratings.vue")
	},
	{
		path: "*",
		name: "404",

		meta: { title: "Not found" },

		component: () => import(/* WebpackChunkName: "error" */ "../views/NotFound.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	routes: routes
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		let title = "FilmInfo";
		if (to.meta.title) title = to.meta.title + " - " + title;
		document.title = title;
	});
});

export default router;
<template>
	<b-alert dismissible fade @dismissed="clearError" :show="show" :class="$style.toast" variant="danger" class="text-left">
		<b>Error</b>: {{ error }}
	</b-alert>
</template>

<script>
	const DISPLAY_TIME = 5;

	export default {
		computed: {
			error: function() {
				return this.$store.state.error;
			},
			show: function() {
				return this.error? DISPLAY_TIME: false;
			}
		},
		methods: {
			clearError: function() {
				this.$store.commit("SET_ERROR", null);
			}
		}
	};
</script>

<style lang="scss" module>
	$width: 350px;

	div.toast {
		width: $width;
		margin-left: -$width / 2;
		position: fixed;
		left: 50%;
		top: 4.5rem;
		z-index: 1;
	}
</style>
/* eslint-disable quote-props, max-lines, no-console, no-process-env */

import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

Vue.use(Vuex);

const API_URL = process.env.NODE_ENV == "development"? "http://localhost:3000/api/v1/": "/api/v1/";

export default new Vuex.Store({
	state: {
		language: "de-DE",
		loginState: document.cookie.includes("FilmInfoSession")? "loggedIn": null,
		requestState: null,

		searchResults: [],
		onWatchlist: false,
		views: [],

		general: null,
		trailer: null,
		fullPlot: null,
		cast: null,
		crew: null,
		persons: {},
		trivia: null,
		quotes: null,
		series: null,
		recommendations: null,
		ratings: null,
		reviews: null,
		parental: null,
		streams: null,
		medium: null,
		dubbing: null,
		images: null,
		sources: null,

		progress: 100,
		error: ""
	},
	mutations: {
		"SET_LOGIN_STATE": function(state, loginState) {
			state.loginState = loginState;
		},
		"SET_REQUEST_STATE": function(state, requestState) {
			state.requestState = requestState;
		},
		"SET_SEARCH_RESULTS": function(state, results) {
			state.searchResults = results;
		},
		"SET_FULL_PLOT": function(state, fullPlot) {
			state.fullPlot = fullPlot;
		},
		"SET_PROGRESS": function(state, progress) {
			state.progress = progress;
		},
		"SET_PERSON": function(state, { id, data }) {
			state.persons[id] = data;
			state.persons = { ...state.persons };
		},
		"SET_REVIEW": function(state, { provider, id, text }) {
			state.reviews[provider][id].text = text;
		},
		"SET_IMAGE_GALLERY": function(state, images) {
			state.images = images;
		},
		"SET_ON_WATCHLIST": function(state, onWatchlist) {
			state.onWatchlist = onWatchlist;
		},
		"ADD_VIEW": function(state, data) {
			state.views.push(data);
			state.views.sort((a, b) => new Date(b.date) - new Date(a.date));
		},
		"RESET": function(state) {
			state.general = null;
			state.trailer = null;
			state.fullPlot = null;
			state.cast = null;
			state.crew = null;
			state.trivia = null;
			state.quotes = null;
			state.series = null;
			state.recommendations = null;
			state.ratings = null;
			state.reviews = null;
			state.parental = null;
			state.streams = null;
			state.medium = null;
			state.dubbing = null;
			state.images = null;
			state.sources = null;
			state.views = [];
			state.progress = 100;
			state.error = "";
		},
		"SET_ERROR": function(state, error) {
			state.error = error;
			if (error) console.error(error);
		},
		"MERGE_PROPERTY": function(state, { property, data }) {
			if (!Object.prototype.hasOwnProperty.call(state, property)) return mergeError(property, "State does not contain a definition for it.");
			if (!Object.prototype.hasOwnProperty.call(data, property)) return mergeError(property, "Invalid data object.", data);

			if (state[property] === null) state[property] = {};
			if (Array.isArray(data[property])) {
				state[property] = data[property];
			}
			else if (typeof data[property] === "object") {
				state[property] = { ...state[property], ...data[property] };
			}
			else {
				state[property] = data[property];
			}
		}
	},
	actions: {
		"LOGIN": async function(context, payload) {
			context.commit("SET_LOGIN_STATE", "pending");

			let success = await postRequest("login", payload, context, true);
			context.commit("SET_LOGIN_STATE", success? "loggedIn": "failed");
		},
		"LOGOUT": async function(context, sendToServer = true) {
			if (sendToServer) await postRequest("logout", {}, context);
			document.cookie = "FilmInfoSession=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
			context.commit("SET_LOGIN_STATE", null);
		},
		"REGISTER": async function(context, payload) {
			context.commit("SET_REQUEST_STATE", "pending");

			let success = await postRequest("register", payload, context, true);
			context.commit("SET_REQUEST_STATE", success? "success": "failed");
		},
		"CHANGE_PASSWORD": async function(context, newPassword) {
			context.commit("SET_REQUEST_STATE", "pending");

			let success = await postRequest("change_password", { password: newPassword }, context, true);
			context.commit("SET_REQUEST_STATE", success? "success": "failed");
		},
		"DELETE_ACCOUNT": async function(context, password) {
			context.commit("SET_REQUEST_STATE", "pending");

			let success = await postRequest("delete_account", { password: password }, context, true);
			if (success) setTimeout(() => context.dispatch("REQUEST_LOGIN"), 2000);

			context.commit("SET_REQUEST_STATE", success? "success": "failed");
		},
		"REQUEST_LOGIN": function(context) {
			context.dispatch("LOGOUT", false);

			router.push({ name: "login" });
		},
		"SEARCH": async function(context, { endpoint, term }) {
			if (!endpoint || !term) throw new Error("Invalid parameters passed to SEARCH action");

			context.commit("SET_SEARCH_RESULTS", []);
			context.commit("RESET");
			context.commit("SET_PROGRESS", 0);

			let json = await request(endpoint, term, context, true);
			context.commit("SET_PROGRESS", 100);

			if (json === null) return;

			if (json.length == 1) {
				let result = json[0];
				router.push({
					name: result.media_type,
					params: { id: result.id }
				});
			}
			else {
				context.commit("SET_SEARCH_RESULTS", json);
			}
		},
		"SEARCH_NAME": function(context, term) {
			return context.dispatch("SEARCH", { endpoint: "search", term: term });
		},
		"SEARCH_EAN": function(context, ean) {
			return context.dispatch("SEARCH", { endpoint: "search/ean", term: ean });
		},
		"LOAD_FILM": function(context, { id, type }) {
			if (context.state.sources && context.state.sources.tmdb == id) return;

			context.commit("RESET");
			if (id) liveRequest(type, id, context, true);
		},
		"LOAD_PERSON": async function(context, id) {
			let person = await request("person", id, context, false);
			if (!person) person = null;

			context.commit("SET_PERSON", {
				id: id,
				data: person
			});
		},
		"LOAD_REVIEW": async function(context, { provider, id }) {
			let review = await request("review", provider + "/" + id, context, false);
			if (!review) review = "Failed to load review :(";

			context.commit("SET_REVIEW", {
				provider: provider,
				id: id,
				text: review
			});
		},
		"LOAD_FULL_PLOT": async function(context) {
			let data = context.state.general.original_title + "/" + context.state.general.year;
			let json = await request("plot", data, context, false);
			if (!json) return context.commit("SET_FULL_PLOT", "No plot details available :(");

			context.commit("SET_FULL_PLOT", json.plot_full);
			context.commit("MERGE_PROPERTY", {
				property: "sources",
				data: json
			});
		},
		"SAVE_RATING": async function(context, payload) {
			const endpoint = `rating/${context.state.general.type}/${context.state.sources.tmdb}`;
			let success = await postRequest(endpoint, payload, context, true, "PUT");
			if (success) context.commit("ADD_VIEW", payload);
		},
		"REMOVE_RATING": function(context, { id }) {
			return restRequest(`rating/${id}`, null, "DELETE", context, true);
		},
		"GET_RATING_LIST": async function(context) {
			let json = await request("ratings", null, context);
			return json? json.ratings: [];
		},
		"ADD_TO_WATCHLIST": async function(context, { type, id }) {
			let data = type + "/" + id;
			let success = await restRequest("watchlist", data, "PUT", context, true);

			if (success) context.commit("SET_ON_WATCHLIST", true);
		},
		"REMOVE_FROM_WATCHLIST": async function(context, { type, id }) {
			let data = type + "/" + id;
			let success = await restRequest("watchlist", data, "DELETE", context, true);

			if (success) context.commit("SET_ON_WATCHLIST", false);
		},
		"GET_WATCHLIST": async function(context) {
			let json = await request("watchlist", "", context);

			context.commit("SET_SEARCH_RESULTS", null);

			return json? json.watchlist: [];
		},
		"MERGE_DATA": function(context, data) {
			let properties = [
				"general",
				"trailer",
				"cast",
				"crew",
				"trivia",
				"quotes",
				"series",
				"recommendations",
				"ratings",
				"reviews",
				"parental",
				"streams",
				"dubbing",
				"sources",
				"onWatchlist",
				"views"
			];

			for (let property of properties) {
				if (!Object.prototype.hasOwnProperty.call(data, property)) continue;

				context.commit("MERGE_PROPERTY", {
					property: property,
					data: data
				});
			}
		}
	},
	getters: {
		hasData: function(state) {
			return state.general !== null;
		},
		languageParameter: function(state) {
			return "?lang=" + state.language;
		},
		loggedIn: function(state) {
			return state.loginState == "loggedIn";
		},
		requestSucceeded: function(state) {
			return state.requestState == "success";
		}
	}
});

function liveRequest(endpoint, data, context) {
	context.commit("SET_PROGRESS", 5);
	let eventSource = new EventSource(API_URL + "live/" + endpoint + "/" + data + context.getters.languageParameter, { withCredentials: true });

	eventSource.onopen = () => console.log("Connected to live API '" + endpoint + "'", data);

	eventSource.addEventListener("complete", () => {
		console.log("Live request '" + endpoint + "' complete", data);
		context.commit("SET_PROGRESS", 100);
		eventSource.close();
	});

	eventSource.onmessage = ($event) => {
		let json = JSON.parse($event.data);
		console.log(json);

		context.commit("SET_PROGRESS", json.progress);
		if (isError(json)) {
			// Some data fragments can have no data, so we don't want to display error messages
			// for these, except if it's the main data set.
			if (json.status == 404 && json.action !== "tmdb-film") return;

			// All other errors are unexpected and should be displayed.
			return context.commit("SET_ERROR", json.error);
		}

		if (json.action == "imdb-images") return context.commit("SET_IMAGE_GALLERY", json.result);

		context.dispatch("MERGE_DATA", json.result);
	};

	eventSource.onerror = (e) => {
		console.error("Failed to receive data", e);
		context.commit("SET_PROGRESS", 100);
		context.commit("SET_ERROR", "Failed to receive data");
		router.push({ name: "login" });
	};
}

async function request(endpoint, data, context, displayErrors = false, options = undefined) {
	const HTTP_FORBIDDEN = 403;

	data = data? ("/" + data + context.getters.languageParameter): "";
	if (!options) options = {};

	options.credentials = "include";

	try {
		let response = await fetch(API_URL + endpoint + data, options);
		if (response.status === HTTP_FORBIDDEN) return context.dispatch("REQUEST_LOGIN");

		return await getJson(response);
	}
	catch (exception) {
		if (displayErrors) context.commit("SET_ERROR", exception.message || exception);
	}

	return null;
}

function postRequest(endpoint, data, context, displayErrors = false, method = "POST") {
	let options = {
		method,
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	};

	return request(endpoint, null, context, displayErrors, options);
}

function restRequest(endpoint, data, method, context, displayErrors = false) {
	return request(endpoint, data, context, displayErrors, { method: method });
}

async function getJson(response) {
	let json = null;

	try {
		json = await response.json();
	}
	catch (exception) {
		throw new Error(response.status + " - " + response.statusText);
	}

	if (isError(json)) throw json.error;
	return json.result;
}

function isError(json) {
	if (json.status == 200 && !json.error) return false;

	console.warn(json.action, json.status, json.error);
	return true;
}

function mergeError(sProperty, sReason, ...data) {
	console.error("Cannot merge property " + sProperty + ". " + sReason, ...data);
}
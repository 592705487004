<template>
	<footer class="mt-auto">
		<b-container>
			<b-row flex class="mb-2">
				<b-col cols="5" :class="$style.credits" class="text-left">
					FilmInfo, a project by <a href="https://bioruebe.com" target="_blank">Bioruebe</a> 2020 |
					<a href="https://bioruebe.com/legal/" target="_blank">Legal</a> |
					<a href="https://bioruebe.com/privacy/" target="_blank">Privacy Policy</a>
				</b-col>
				<b-col></b-col>
				<b-col v-if="sources" cols="6" class="text-right">
					Related: <a :href="tmdb" target="_blank">TMDB</a>
					<span v-if="sources.imdb">, <a :href="imdb" target="_blank">IMDB</a></span>
					<span v-if="sources.ofdb">, <a :href="ofdb" target="_blank">OFDB</a></span>
					<span v-if="sources.justwatch">, <a :href="justwatch" target="_blank">JustWatch</a></span>
					<span v-if="azn">, <a :href="azn" target="_blank">AZN</a></span>
					<span v-if="sources.wikipedia">, <a :href="sources.wikipedia" target="_blank">Wikipedia</a></span>,
					<a :href="schnittberichte" target="_blank">Schnittberichte</a>
					<span v-if="sources.synchronkartei">, <a :href="synchronkartei" target="_blank">Synchronkartei</a></span>
				</b-col>
			</b-row>
		</b-container>
	</footer>
</template>

<script>
	export default {
		data() {
			return {
			};
		},
		computed: {
			safeTitle() {
				return this.$store.state.general.original_title.replace(/[&/\\#$~%'"?!]/g, "");
			},
			sources() {
				return this.$store.state.sources;
			},
			tmdb() {
				return "https://www.themoviedb.org/movie/" + this.$store.state.sources.tmdb;
			},
			imdb() {
				return "https://www.imdb.com/title/" + this.$store.state.sources.imdb;
			},
			ofdb() {
				return "https://ofdb.de/film/" + this.$store.state.sources.ofdb;
			},
			justwatch() {
				return "https://www.justwatch.com" + this.$store.state.sources.justwatch;
			},
			azn() {
				let title = this.$store.state.general.original_title;
				title = title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

				return `https://www.aznude.com/view/movie/${title.substr(0, 1)}/${title}.html`;
			},
			schnittberichte() {
				return "https://www.schnittberichte.com/svds.php?Page=Suche&String=" + this.safeTitle;
			},
			synchronkartei() {
				return "https://www.synchronkartei.de/" + this.sources.synchronkartei;
			}
		}
	};
</script>

<style lang="scss" module>
	.credits a {
		color: inherit;
		font-weight: 600;

		&:hover {
			color: inherit;
		}
	}
</style>